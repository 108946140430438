/*
StimulusPhotoSwipe 5.1.0
*/
import PhotoSwipe from 'photoswipe';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import { Controller } from '@hotwired/stimulus';

class UsePhotoSwipe {
    constructor(controller, options) {
        let element = options.element;
        if (element === undefined) {
            element = controller.element;
        }
        const pswpOptions = options.photoswipe;
        const lightbox = new PhotoSwipeLightbox(Object.assign({ gallery: element, children: 'a', pswpModule: PhotoSwipe }, pswpOptions));
        if (options.handlers !== undefined) {
            Object.entries(options.handlers).forEach(([eventName, handlers]) => {
                for (let i = 0; i < handlers.length; i++) {
                    const handler = handlers[i].bind(controller);
                    lightbox.on(eventName, handler);
                }
            });
        }
        if (options.filters !== undefined) {
            Object.entries(options.filters).forEach(([eventName, filters]) => {
                for (let i = 0; i < filters.length; i++) {
                    const filter = filters[i].bind(controller);
                    lightbox.addFilter(eventName, filter);
                }
            });
        }
        lightbox.init();
        this.lightbox = lightbox;
    }
    destroy() {
        if (this.lightbox !== undefined) {
            this.lightbox.destroy();
            this.lightbox = undefined;
        }
    }
}
function usePhotoSwipe(controller, options) {
    const photoswipe = new UsePhotoSwipe(controller, options);
    const controllerDisconnect = controller.disconnect.bind(controller);
    Object.assign(controller, {
        disconnect() {
            controllerDisconnect();
            photoswipe.destroy();
        }
    });
    return photoswipe;
}

class PhotoSwipeController extends Controller {
    connect() {
        this.lightbox = usePhotoSwipe(this, {
            element: this.element,
            photoswipe: this.pswpOptionsValue
        }).lightbox;
    }
    loadAndOpen(e) {
        var _a;
        const currentTarget = e.currentTarget;
        const slideIndex = currentTarget.dataset.pswpSlideIndex || '0';
        (_a = this.lightbox) === null || _a === void 0 ? void 0 : _a.loadAndOpen(parseInt(slideIndex), {
            gallery: this.element
        });
    }
}
PhotoSwipeController.values = {
    pswpOptions: Object
};

export { PhotoSwipeController, UsePhotoSwipe, usePhotoSwipe };
